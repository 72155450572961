import React from 'react';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import CategoryMenu from '../category-menu';
import styles from './app-root.scss';

const AppRoot = withReduxStore(() => {
  return (
    <ResponsiveListener dataHook="category-menu-root" className={styles.baseStyles}>
      <CategoryMenu />
    </ResponsiveListener>
  );
});

export default withReduxStore(AppRoot);
