import { createStylesParam, StyleParamType, wixColorParam, wixFontParam } from '@wix/tpa-settings';

export const showAllPostsCategory = createStylesParam('showAllPostsCategory', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showPostCount = createStylesParam('showPostCount', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => true,
});

export const showAllPostsCategoryMobile = createStylesParam('showAllPostsCategoryMobile', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const showPostCountMobile = createStylesParam('showPostCountMobile', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export const backgroundColorMobile = createStylesParam('backgroundColorMobile', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1', 1),
});

export const borderColorMobile = createStylesParam('borderColorMobile', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});

export const separatorColorMobile = createStylesParam('separatorColorMobile', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});

export const separatorWidthMobile = createStylesParam('separatorWidthMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const textColorMobile = createStylesParam('textColorMobile', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const isMobileDisplaySettingsEnabled = createStylesParam(
  'category-menu-mobile-isDisplaySettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const isMobileDesignSettingsEnabled = createStylesParam(
  'category-menu-mobile-isDesignSettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const isMobileLayoutSettingsEnabled = createStylesParam(
  'category-menu-mobile-isLayoutSettingsEnabled',
  {
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
);

export const borderWidthMobile = createStylesParam('borderWidthMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => 1,
});

export const cornerRadiusMobile = createStylesParam('cornerRadiusMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const textFontMobile = createStylesParam('textFontMobile', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 15,
  }),
});

export const textAlignmentMobile = createStylesParam('textAlignmentMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const textPaddingHorizontalMobile = createStylesParam('textPaddingHorizontalMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const textPaddingVerticalMobile = createStylesParam('textPaddingVerticalMobile', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const backgroundColorHoverMobile = createStylesParam('backgroundColorHoverMobile', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});

export const textColorHoverMobile = createStylesParam('textColorHoverMobile', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const textColorHover = createStylesParam('textColorHover', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const backgroundColor = createStylesParam('backgroundColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-1', 1),
});

export const borderColor = createStylesParam('borderColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});

export const separatorColor = createStylesParam('separatorColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});

export const separatorWidth = createStylesParam('separatorWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const textColor = createStylesParam('textColor', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-5'),
});

export const borderWidth = createStylesParam('borderWidth', {
  type: StyleParamType.Number,
  getDefaultValue: () => 1,
});

export const cornerRadius = createStylesParam('cornerRadius', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const textFont = createStylesParam('textFont', {
  type: StyleParamType.Font,
  getDefaultValue: wixFontParam('Body-M', {
    size: 15,
  }),
});

export const textAlignment = createStylesParam('textAlignment', {
  type: StyleParamType.Number,
  getDefaultValue: () => 0,
});

export const textPaddingHorizontal = createStylesParam('textPaddingHorizontal', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const textPaddingVertical = createStylesParam('textPaddingVertical', {
  type: StyleParamType.Number,
  getDefaultValue: () => 12,
});

export const backgroundColorHover = createStylesParam('backgroundColorHover', {
  type: StyleParamType.Color,
  getDefaultValue: wixColorParam('color-2', 0.75),
});
