import React, { type PropsWithChildren } from 'react';
import styles from './link-text.scss';

type Props = PropsWithChildren<{
  postCount?: React.ReactNode;
  a11yText?: React.ReactNode;
}>;

const LinkText: React.FC<Props> = ({ children, postCount, a11yText }) => {
  return (
    <>
      {children}{' '}
      <span className={styles.postCount}>
        <span aria-hidden={true}>{postCount}</span>{' '}
        <span className={styles.srOnly}>{a11yText}</span>
      </span>
    </>
  );
};

export default LinkText;
